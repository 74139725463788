import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';

import PressReleaseListItem from '../PressReleaseListItem';

const PressReleasesList = ({ pressReleases = {}, path }) => {
  
  // Debugging log:
  console.log("PressReleases Data:", pressReleases);

  // Extract the data array from pressReleases
  const releasesArray = pressReleases.data || [];

  const list = releasesArray.map((release, index) => {
    if (!release || !release.id) {
      // If any data is missing, don't render this item
      return null;
    }
  
    return (
      <PressReleaseListItem
        pressRelease={release}
        id={release.id}
        key={`press-release-${index}`}
        path={path}
      />
    );
  }).filter(Boolean); // Filter out any null values

  return <Row>{list}</Row>;
};

PressReleasesList.propTypes = {
  pressReleases: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default PressReleasesList;

 