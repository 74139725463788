import React from "react"
import Hero from "../../components/Hero"
import HelmetWrapper from "../../components/HelmetWrapper"
import ContentBlock from "../../components/ContentBlock"
import PressReleasesList from "../../components/PressReleasesList"
import newsHero from "../../assets/images/header_news.jpg"
import Layout from "../../components/Layout"

const pageTitle = "Newsroom | Harmony Biosciences"
const pageDescription =
  "Explore the Newsroom for announcements about Harmony Biosciences. Find out about company and clinical trial news and investor updates."
const pageKeywords = "harmony biosciences - 1,300, central nervous system disorders - 1,300"

class Newsroom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pressReleases: [],
    }
  }

  componentDidMount() {
    fetch("/.netlify/functions/newsroom")
      .then(response => response.json())
      .then(data => {
        this.setState({ pressReleases: data })
      })
      .catch(error => console.error("Error fetching the news data:", error))
  }

  render() {
    return (
      <Layout jobCode="US-HAR-2200050/Jun 2022">
        <div id="newsroom">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug="/newsroom"
          />
          <Hero bg={newsHero}>
            <h1>Newsroom</h1>
          </Hero>

          <ContentBlock color="white" className="newsroom-content">
            <p>
              <strong>Media Inquiries:</strong>
              <br />
              <a
                style={{ wordBreak: "break-all", color: "blue" }}
                href="mailto:communications@harmonybiosciences.com"
              >
                communications@harmonybiosciences.com
              </a>
            </p>
            <PressReleasesList
              pressReleases={this.state.pressReleases}
              path={"/newsroom/"}
            />
          </ContentBlock>
        </div>
      </Layout>
    )
  }
}

export default Newsroom
