import React, { useState } from "react";
import { Col } from "react-flexbox-grid";

const PressReleaseListItem = ({ pressRelease, id, path }) => {
  const [hovered, setHovered] = useState(false);
  const {
    title,
    link: { hostedUrl: slug },
    releaseDate: { date },
  } = pressRelease;

  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

  return (
    <Col xs={12} style={{ borderBottom: "1px solid #dee2e6", padding: "10px 0" }}>
      <article role="article" style={{ padding: "0.7em 0"}}>
        <div style={{ padding: "0 0 .7em 0", fontSize: "16px", color: "#555" }}>
          {formattedDate}
        </div>
        <div style={{ fontSize: "18px", paddingBottom: "0.7em" }}>
          <a 
            href={slug} 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ 
              color: hovered ? "#61a60e" : "#636569", 
              textDecoration: "none" 
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {title}
          </a>
        </div>
      </article>
    </Col>
  );
}

export default PressReleaseListItem;


